// Imports
// ------
import React, { useState, useEffect } from 'react';
import Logo from '@parts/Logo';

// Styles
// ------
import * as CSS from './Download.module.scss';

// Component
// ------
function MaterialLibraryPopup({ href }) {

    const [showPopup, setShowPopup] = useState(true);
    const [popUpActive, setPopUpActive] = useState(false);

    // Check if popup is hidden forever
    useEffect(() => {
        if (href && localStorage.getItem('hidePopup') === "true") {
            setShowPopup(false);
        }
    }, [])

    // Form submit
    const [state, setState] = useState({})
    const [formSubmitted, setFormSubmitted] = useState(false)
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    function encode(data) {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => {
                setFormSubmitted(true);
                localStorage.setItem('hidePopup', "true");
                setShowPopup(false);
                window.open(href, '_blank');
            })
            .catch((error) => alert(error))
    }

    return (
        <div className={CSS.section}>
            {!showPopup ?
                <a className={CSS.download} href={href}>Download</a>
                :
                <>
                    <button className={CSS.download} onClick={() => setPopUpActive(true)}>Download</button>
                    {popUpActive &&
                        <>
                            <div className={CSS.popup}>
                                <p className={CSS.bgText}>Please enter your email to download this asset. We may email you asking for your feedback to ask your thoughts and how we can improve the M-XR library.</p>

                                <form className={CSS.form} name="Email Address from Material Library" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>

                                    <input type="hidden" name="form-name" value="contact" />
                                    <p hidden>
                                        <label>
                                            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                                        </label>
                                    </p>

                                    <input type="email" name="email" onChange={handleChange} placeholder="EMAIL*" required />

                                    <input className={CSS.download} type="submit" value="Download" />
                                </form>

                                <button style={{ fontSize: '1.5rem', marginTop: '0.3rem' }} onClick={() => setPopUpActive(false)}>GO BACK</button>
                            </div>
                            <div onClick={() => setPopUpActive(false)} className={CSS.bg} />
                        </>
                    }
                </>
            }
        </div>
    );
}

export default MaterialLibraryPopup;