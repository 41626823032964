// extracted by mini-css-extract-plugin
export var section = "MaterialLibrary-module--section--2UndA";
export var blur = "MaterialLibrary-module--blur--3ZBKJ";
export var sidebar = "MaterialLibrary-module--sidebar--1CezK";
export var mobileMenuButton = "MaterialLibrary-module--mobileMenuButton--1gy7-";
export var materialMenu = "MaterialLibrary-module--materialMenu--3YJZ2";
export var activeCategory = "MaterialLibrary-module--activeCategory--ll_Ef";
export var activeSubclass = "MaterialLibrary-module--activeSubclass--1muRT";
export var sidebarLogo = "MaterialLibrary-module--sidebarLogo--CtFlr";
export var goBack = "MaterialLibrary-module--goBack--oakJ-";
export var sidebarLine = "MaterialLibrary-module--sidebarLine--2UYu8";
export var logoAndMobileMenuButton = "MaterialLibrary-module--logoAndMobileMenuButton--2_7Cg";
export var showOnlyActiveCategory = "MaterialLibrary-module--showOnlyActiveCategory--3hVbU";
export var menuOpen = "MaterialLibrary-module--menuOpen--3X3qv";
export var main = "MaterialLibrary-module--main--1lH0X";
export var backgroundActive = "MaterialLibrary-module--backgroundActive--2W9m-";
export var introduction = "MaterialLibrary-module--introduction--2PlVC";
export var scannedMaps = "MaterialLibrary-module--scannedMaps--RIzxH";
export var available = "MaterialLibrary-module--available--6Pw2D";
export var notAvailable = "MaterialLibrary-module--notAvailable--1ur4S";
export var password = "MaterialLibrary-module--password--1mbQZ";
export var legals = "MaterialLibrary-module--legals--2xj9M";
export var enterAndRequest = "MaterialLibrary-module--enterAndRequest--2UdZa";
export var enter = "MaterialLibrary-module--enter--1F-Cx";
export var materialList = "MaterialLibrary-module--materialList--276UV";
export var materials = "MaterialLibrary-module--materials--i8CvG";
export var pagination = "MaterialLibrary-module--pagination--9irfI";
export var activePagination = "MaterialLibrary-module--activePagination--28ul4";
export var activeMaterial = "MaterialLibrary-module--activeMaterial--1DnPc";
export var previewInformation = "MaterialLibrary-module--previewInformation--3X9k1";
export var measuredProperties = "MaterialLibrary-module--measuredProperties--3hAso";
export var previewImage = "MaterialLibrary-module--previewImage--2ZlEq";
export var previewImageWrapper = "MaterialLibrary-module--previewImageWrapper--32LP_";
export var activePass = "MaterialLibrary-module--activePass--1CF8l";
export var attributeMaterials = "MaterialLibrary-module--attributeMaterials--2rjxD";
export var active = "MaterialLibrary-module--active--1RNQ6";
export var nextMaterials = "MaterialLibrary-module--nextMaterials--2olqG";
export var previousMaterials = "MaterialLibrary-module--previousMaterials--2Slys";
export var close = "MaterialLibrary-module--close--mtbm_";
export var tags = "MaterialLibrary-module--tags--1tnrD";
export var details = "MaterialLibrary-module--details--2UjOj";
export var links = "MaterialLibrary-module--links--25MZF";