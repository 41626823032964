// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@lay/Seo';
import PageTransition from '@parts/PageTransition';
import { headerState } from '@states/header';
import { action } from 'mobx';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import MaterialLibrary from '../components/MaterialLibrary/MaterialLibrary'

// Component
// ------
function MaterialLibraryPage({ transitionStatus, entry, exit, }) {
    const ts = transitionStatus;
    const bp = useBreakpoint();

    useEffect(() => {
        const hideNotification = action(() => {
            headerState.notifyActive = false;
            headerState.notifySize = 0;
        });

        hideNotification();

        const timer = setTimeout(
            () => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            },
            bp.large ? 500 : 1000
        );

        return () => {
            clearTimeout(timer);
        };
    }, []);

    return (
        <>
            <Seo data={{
                title: 'Material Library by M-XR',
                desc: 'Material library provided by M-XR',
                type: 'page',
                image: null,
            }} backupData={''} />

            <style>
                {` header { display: none; } `}
            </style>

            <PageTransition status={ts} entry={entry} exit={exit}>
                <MaterialLibrary />
            </PageTransition>
        </>
    );
}

export default MaterialLibraryPage;