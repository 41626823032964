// Imports
// ------
import React, { useState } from 'react';
import Logo from '@parts/Logo';

// Styles
// ------
import * as CSS from './PopUp.module.scss';

// Component
// ------
function MaterialLibraryPopup({ href, text }) {

    const [popUpActive, setPopUpActive] = useState(false);

    // Form submit
    const [state, setState] = useState({})
    const [formSubmitted, setFormSubmitted] = useState(false)
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    function encode(data) {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => {
                setFormSubmitted(true);
            })
            .catch((error) => alert(error))
    }

    return (
        <>
            <button className={href ? CSS.download : CSS.asText} onClick={() => setPopUpActive(true)}>{text}</button>
            {popUpActive &&
                <>
                    <div className={CSS.popup}>
                        <h2>REQUEST ACCESS</h2>
                        <p className={CSS.bgText}>Welcome to the M-XR closed BETA asset library. Available assets were created using our proprietary 3D scanning pipeline. To be added to the closed BETA waitlist please enter your name, email and profession.</p>

                        {!formSubmitted &&
                            <form className={CSS.form} name="Material Library register" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>


                                <input type="hidden" name="form-name" value="contact" />
                                <p hidden>
                                    <label>
                                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                                    </label>
                                </p>

                                <input type="text" name="name" onChange={handleChange} placeholder="NAME*" required />
                                <input type="email" name="email" onChange={handleChange} placeholder="EMAIL*" required />
                                <input type="text" name="profession" onChange={handleChange} placeholder="PROFESSION*" required />
                                <input type="submit" value="SUBMIT" />

                            </form>
                        }

                        {formSubmitted &&
                            <p style={{ margin: '2em 0' }} className={CSS.success}>Successfully sent. One of our team members will be in touch with you soon.</p>
                        }

                        <button onClick={() => setPopUpActive(false)}>GO BACK</button>
                    </div>
                    <div onClick={() => setPopUpActive(false)} className={CSS.bg} />
                </>
            }
        </>
    );
}

export default MaterialLibraryPopup;