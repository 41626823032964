// Imports
// ------
import React from 'react';
import { Helmet } from 'react-helmet';

// Component
// ------
function Seo({ data, backupData }) {
	const title = data ? data.title : backupData.title;
	const desc = data ? data.desc : backupData.desc;
	const type = data ? data.type : backupData.type;
	const image = data.image
		? data.image.url
		: backupData.image
			? backupData.image.url
			: null;

	return (
		<Helmet>
			<title>{title}</title>
			<meta property='og:title' content={title} />
			<meta property="twitter:title" content={title} />

			<meta property='og:description' content={desc} />
			<meta name="twitter:description" content={desc} />

			<meta property="twitter:card" content="summary_large_image" />
			<meta property='og:type' content={type} />

			{image && <meta property='og:image' content={image} />}
			{image && <meta property="twitter:image" content={image} />}
		</Helmet>
	);
}

export default React.memo(Seo);
